import React from 'react'
import styles from './Bio.module.css'

function Bio(props) {

  return (
    <div className={`
      ${styles.Bio}
      ${props.className || ''}
    `}>
      <p><i>
        Go beyond price/sqft and find the properties that present the best value for investors or anyone looking to buy. Order ranked lists for the cities you care about most.
      </i>
      <br/>
      </p>
    </div>
  )
}

export default Bio
