import React from "react";
import SideMenu from "../toolbar/sidemenu/SideMenu";
import "./Toolbar.css";
import logo from './rerLogoCropped.webp'


const toolbar = props => (
  <header className="toolbar">
    <nav className="toolbar_navigator">
      <div />
      <div className="toggle-btn">
        <SideMenu click={props.drawerToggleClickHandler} />
      </div>
      <div className="toolbar_logo">
        <a href="/"><img width="157" height="100"  src={logo} alt="Real Estate Ranker Logo" /></a>
      </div>
      <div className="spacer" />
      <div className="toolbar_navigation-items">
        <ul>
          <li>
            <a href="https://square.link/u/iYZmftDl" target="_blank" rel="noopener noreferrer" >Order a Ranked List</a>
          </li>
          <li>
            •
          </li>
          <li>
            <a href="/about">About</a>
          </li>
          <li>
            •
          </li>
          <li>
            <a href="/contact">Contact</a>
          </li>
          <li>
            •
          </li>
          <li>
            <a href="/faqs">FAQs</a>
          </li>
          <li>
            •
          </li>
          <li>
            <a href="/tags">Tags</a>
          </li>
        </ul>
      </div>
    </nav>
  </header>
);

export default toolbar;
