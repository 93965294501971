var metaTagData = [
  {"path":"/posts/2021-12-03/Welcome-to-Real-Estate-Ranker/","description":"Welcome and thanks for being here! Real Estate Ranker provides insight into the value of real estate properties. Most people know to look at price per square foot but beyond that it's a lot of guesswork.", "image":"/static/media/firstposthouse.7e3ba46a.jpg"},
  {"path":"/posts/2021-12-08/Best-Value-Properties-in-Leesburg-VA/","description":"We chose Leesburg, VA for our very first ranking list post because it's been recommended by several publications as one of the best places to live. Because we're on the west coast, it also seemed ", "image":null},
  {"path":"/posts/2021-12-10/Best-Value-Properties-in-Fort-Collins-CO/","description":"Could Fort Collins, CO be the best place to live in the entire country? Livability thinks so. We don't exactly know but we can certainly take a look at what's for sale and determine what real", "image":null},
  {"path":"/posts/2021-12-13/Best-Value-Properties-in-Sarasota-FL/","description":"Some consider Sarasota, FL one of the best places to live in the country. There are certainly a ton of great properties to consider, so let's take a look at some that represent the best value", "image":null},
  {"path":"/posts/2021-12-23/Best-Value-Properties-in-Frisco-TX/","description":"In 2018, Money Magazine named Frisco, TX the best place to live in the U.S. So if that wasn't reason enough to look at the properties available in this Dallas suburb, then the fact that lots of peopl", "image":null},
  {"path":"/posts/2021-12-27/Best-Value-Properties-in-Overland-Park-KS/","description":"Livability called Overland Park, KS the #1 best place for families to live in the U.S. That's a high distinction, so let's look at the real estate properties for sale that have the best va", "image":null},
  {"path":"/posts/2021-12-29/2022-Looking-at-Real-Estate-Market-Predictions/","description":"Realtor.com, Redfin, and Zillow have all put out some end-of-the-year predictions for the real estate market conditions for 2022. Let's take a look at what they're saying we can expect", "image":"/static/media/122921.c5ee5b52.jpg"},
  {"path":"/posts/2022-01-03/Best-Value-Properties-in-South-Lake-Tahoe-CA/","description":"The most popular place of 2021? South Lake Tahoe, CA. According to Zillow, South Lake Tahoe wins 'based on Zillow page-view traffic, available housing inventory, price appreciation and ", "image":null},
  {"path":"/posts/2022-01-05/Best-Value-Properties-in-Portland-ME/","description":"There are two great American cities called Portland. Today we're looking at the smaller one and our first New England city, Portland, Maine. U.S. News & World Report called Portland ", "image":null},
  {"path":"/posts/2022-01-07/Best-Value-Properties-in-Franklin-TN/","description":"Franklin, TN, a suburb of Nashville, has been called one of the best places to live in the U.S. for 2021. Nearby Hendersonville has also made this list recently which points to the fact that there is ", "image":"/static/media/heroImage.cb5ef220.webp"},
  {"path":"/posts/2022-01-12/Best-Value-Properties-in-Madison-WI/","description":"The best place to live in the entire U.S. is Madison, Wisconsin, according to livability. Madison gets good marks in most any category you can think of --infrastructure, economy, recreation,", "image":"/static/media/heroImage.53e24765.webp"},
  {"path":"/posts/2022-01-18/Best-Value-Properties-in-Lafayette-IN/","description":"PolicyGenius has an interesting list that we've been digging into. It's their 'Best Cities to Invest in Real Estate in 2022' list. Lafayette, IN made the list so today we'll take a look at this ", "image":"/static/media/heroImage.892cafee.webp"},
  {"path":"/posts/2022-01-20/Best-Value-Properties-in-Worcester-MA/","description":"Worcester, Massachusetts is about 50 miles outside of Boston, but is a large, almost 200,000-person city in its own right. It's another ideal city for investors. Fortune calls Worcester one of the ", "image":"/static/media/heroImage.65ae64ce.webp"},
  {"path":"/posts/2022-01-25/Best-Value-Properties-in-Independence-MO/","description":"Two different services, the Motley Fool and PolicyGenius, have designated Independence, Missouri one of the best places to to invest in real estate in 2022. The Motley Fool seems to be", "image":"/static/media/heroImage.e60c3b89.webp"},
  {"path":"/posts/2022-01-27/Best-Value-Properties-in-Youngstown-OH/","description":"We've mentioned the excellent PolicyGenius list of best places to invest in real estate in 2022, so why not go straight to the top and look at the #1 city on the list, Youngstown, OH?", "image":"/static/media/heroImage.9645dbe8.webp"},
  {"path":"/posts/2022-02-01/Best-Value-Properties-in-Boise-ID/","description":"Boise, Idaho has been one of the top cities where people are moving for two years now. It's a destination for people looking to leave California and it's a target for investors due to all", "image":"/static/media/heroImage.353a9adf.webp"},
  {"path":"/posts/2022-02-03/Best-Value-Properties-in-Huntsville-AL/","description":"It's rare to see a city considered to be one of the best places to live also considered to be one of the best places to invest. This is the case with Huntsville, Alabama. One reason", "image":"/static/media/heroImage.c382a587.webp"},
  {"path":"/posts/2022-02-08/Best-Value-Properties-in-Pueblo-CO/","description":"Pueblo, Colorado is a city of about 115,000 people and is just 2 hours South of Denver. Livability calls Pueblo the #4 city for first time homebuyers and, according to PolicyGenius, Pueblo", "image":"/static/media/heroImage.2cd9d41b.webp"},
  {"path":"/posts/2022-02-11/Best-Value-Properties-in-Durham-NC/","description":"Durham, North Carolina often gets lumped together with nearby cities Raleigh (25 miles away) and Chapel Hill (11 miles away), but there's a lot of attention focusing on Durham specifically", "image":"/static/media/heroImage.51aa8049.webp"},
  {"path":"/posts/2022-02-15/Best-Value-Properties-in-Fayetteville-AR/","description":"Fayetteville, Arkansas's housing market has tightened significantly in recent years. Plentiful jobs from large employers and flagging housing supply has driven up demand for Fayettev", "image":"/static/media/heroImage.35ee15b5.webp"},
  {"path":"/posts/2022-02-17/Best-Value-Properties-in-Scottsdale-AZ/","description":"The Phoenix area is mentioned in every real estate list you can imagine: Hottest Real Estate Markets, Most Competitive Rental Markets, Most Popular Places, Best Places to Invest, etc.", "image":"/static/media/heroImage.089bd95a.webp"},
  {"path":"/posts/2022-02-23/how-much-time-can-you-save-with-a-ranking-list/","description":"If you're looking to buy a real estate property for any reason, you've got a lot of work ahead of you. Not only do you need to have financing, budgeting, a timeframe, and a town or city", "image":"/static/media/heroImage.f9d3bb1c.webp"},
  {"path":"/posts/2022-03-02/Best-Value-Properties-in-Des-Moines-IA/","description":"Des Moines, Iowa is a place known for low housing prices and, despite the steep growth in prices since the pandemic, it remains true. It's clear from our ranking list data that prices are highly depe", "image":"/static/media/heroImage.a420a030.jpg"},
  {"path":"/posts/2022-03-09/We-Have-Added-Cap-Rate-and-Cash-on-Cash-Return-to-Ranking-Lists/","description":"Two of the key metrics that real estate investors look for in a property are cap rate aka capitalization rate and cash-on-cash return. In order to bring more value to what we do, we've decided to crun", "image":"/static/media/heroImage.bf370558.webp"},
  {"path":"/posts/2022-03-11/Best-Value-Properties-in-Providence-RI/","description":"Providence, Rhode Island is the city we're looking at today and it's an interesting one. Despite big price increases over the past year, it hasn't kept pace with many other cities in the U.S., includi", "image":"/static/media/heroImage.0365397e.webp"},
  {"path":"/posts/2022-03-17/Best-Value-Properties-in-Riverside-CA/","description":"Just a bit East of L.A., Riverside, California is part of the Inland Empire which also includes San Bernadino and Ontario. It's been the subject of lots of investment speculation in recent years becau", "image":"/static/media/heroImage.a6391c14.webp"},
  {"path":"/posts/2022-03-23/Best-Value-Properties-in-Salt-Lake-City-UT/","description":"Salt Lake City, Utah is known to most Americans as the largest and most prominent city in Utah. It has some of the things that large cities have like an NBA franchise, but it's only the U.S.'s 122nd", "image":"/static/media/heroImage.08beff6d.webp"},
  {"path":"/posts/2022-03-29/Best-Value-Properties-in-Sandy-Springs-GA/","description":"Just north of Atlanta, Sandy Springs, Georgia is a city that frequently gets mentioned alongside Atlanta as a great place to buy an investment property and a great place to live. Sandy Springs is", "image":"/static/media/heroImage.fb68202a.webp"},
  {"path":"/posts/2022-04-06/The-Best-Real-Estate-Investment-Cities/","description":"Instead of creating another list of the best cities to buy real estate, we looked at 22 existing lists produced over the last year and compiled their findings into a master list. Each list uses", "image":"/static/media/heroImage.d7a80e66.webp"},
  {"path":"/posts/2022-04-14/Best-Value-Properties-in-Cincinnati-OH/","description":"With home prices sharply up nationwide, it feels like a good time to look at a city that still has plenty of low-priced real estate properties that could be attractive for homebuyers or invest", "image":"/static/media/heroImage.6cec592e.webp"},
  {"path":"/posts/2022-04-25/Best-Value-Properties-in-Youngstown-OH-UPDATED/","description":"Our January post about Youngstown, Ohio has been one of our most popular so we figured we’d take a look at the real estate properties available there once again. In this post, we won’t", "image":"/static/media/heroImage.60283113.webp"},
  {"path":"/posts/2022-04-28/Best-Value-Properties-in-Omaha-NE/","description":"Omaha, Nebraska, like so much of the U.S., has seen a dramatic spike in real estate prices over the last couple years. Much of it is due to lagging supply which is at roughly one third", "image":"/static/media/heroImage.aa0d4028.webp"}]; export default metaTagData;
