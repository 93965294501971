import { join } from 'path'
import React from 'react'
import { Link } from 'react-navi'
import { formatDate } from '../utils/formats'
import styles from './ArticleMeta.module.css'
import {
  RedditShareButton,
  TwitterShareButton,
  TwitterIcon,
  RedditIcon,
} from "react-share";

function ArticleMeta({ blogRoot, meta, readingTime }) {
  let title
  var slugRegex = new RegExp('[0-9-]{9,11}/([0-9a-zA-Z-]{10,300})');
  try {
    title = meta.slug.match(slugRegex)[1].replace(/-/g," ")
    if (title.slice(-2) === title.slice(-2).toUpperCase()){
      title = title.replace(/ ([^ ]*)$/, ',') + " " + title.slice(-2)
    }
  } catch(e) {
    console.log(e)
    title = "https://www.realestateranker.com/"
  }
  let readingTimeElement
  if (readingTime) {
    let minutes = Math.max(Math.round(readingTime.minutes), 1)
    let cups = Math.round(minutes / 5);
    readingTimeElement =
      <React.Fragment>
        {' '}&bull;{' '}
        <span className={styles.readingTime}>
          {new Array(cups || 1).fill('☕️').join('')} {minutes} min read
        </span>
      </React.Fragment>
  }
      
  return (
    <small className={styles.ArticleMeta}>
      <time dateTime={meta.date.toUTCString()}>{formatDate(meta.date)}</time>
      {
        meta.tags &&
        meta.tags.length &&
        <>
          {' '}&bull;{' '}
          <ul className={styles.tags}>
            {meta.tags.map(tag =>
              <li key={tag}>
                <Link href={join(blogRoot, 'tags', tag)}>{tag}</Link>
              </li>
            )}
          </ul>
        </>
      }
      {readingTimeElement || null}
      {' '}&bull;{' '}
      By <a href="/tags/noah%20blumberg">Noah Blumberg</a>
      {' '}&bull;{' '}
      <TwitterShareButton url={"https://realestateranker.com/posts/"+meta.slug} title={title}>
        <TwitterIcon size={24} round />
      </TwitterShareButton>{' '}
      <RedditShareButton url={"https://realestateranker.com/posts/"+meta.slug} title={title}>
      <RedditIcon size={24} round />
      </RedditShareButton>
    </small>
  )
}

export default ArticleMeta