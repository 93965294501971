export default {
  // The blog's title as it appears in the layout header, and in the document
  // <title> tag.
  title: "Real Estate Ranker",

  description: "Go beyond price/sqft and find the properties that present the best value for investors or anyone looking to buy. Order ranked lists for the cities you care about. This site is a clone of jamesknelson's <a href='https://github.com/jamesknelson/create-react-blog'>npx create-react-blog</a> which is a clone of a clone of Dan Abramov's overreacted.io using create-react-app-mdx and Navi.",

  // The number of posts to a page on the site index.
  indexPageSize: 5,
}
