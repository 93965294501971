import React from "react";
import "./SideDrawer.css";

const sideDrawer = props => {
  let drawerClasses = ["side-drawer"];

  if (props.show) {
    drawerClasses = ["side-drawer", "open"];
  }
  return (
    <nav className={drawerClasses.join(" ")}>
      <ul>
        <li>
          <a href="https://square.link/u/iYZmftDl" target="_blank" rel="noopener noreferrer">Order a Ranked List</a>
        </li>
        <li>
          <a href="/about">About</a>
        </li>
        <li>
          <a href="/contact">Contact</a>
        </li>
        <li>
          <a href="/faqs">FAQs</a>
        </li>
        <li>
          <a href="/tags">Tags</a>
        </li>
      </ul>
    </nav>
  );
};
export default sideDrawer;
