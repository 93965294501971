import React from 'react'
import {
  View,
  Link,
  NotFoundBoundary,
  useLoadingRoute,
} from 'react-navi'
import NotFoundPage from './NotFoundPage'
import LoadingIndicator from './LoadingIndicator'
import styles from './BlogLayout.module.css'
import ToolbarThing from '../ToolbarThing';

function BlogLayout({ blogRoot, isViewingIndex }) {
  let loadingRoute = useLoadingRoute()

  return (
    <div>
    <ToolbarThing />
    <div className={styles.container}>

      <main>
        <NotFoundBoundary render={() => <NotFoundPage />}>
          <View />
        </NotFoundBoundary>
      </main>


      <LoadingIndicator active={!!loadingRoute} />

      {// Don't show the header on index pages, as it has a special header.
      !isViewingIndex && (
        <header>
          {/*<h3 className={styles.title}>
            <Link href={blogRoot}>{siteMetadata.title}</Link>
          </h3>*/}
        </header>
      )}
      


      <footer className={styles.footer}>
        <div>
          <a href="https://square.link/u/iYZmftDl" target="_blank" rel="noopener noreferrer">Order a Ranked List</a>
          {' '}&bull;{' '}
          <Link href="/faqs">FAQs</Link> 
          {' '}&bull;{' '}
          <Link href="/tags">Tags</Link>
          {' '}&bull;{' '}
          <a href="/about">About</a>
          {' '}&bull;{' '}
          <a href="/contact">Contact</a>
        </div>
      </footer>
      
    </div>
    </div>
  )
}

export default BlogLayout
